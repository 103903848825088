body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to top left, #1b31ff, #01eaff);
    color: white;
}

.formContainer {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    width: 100vw;
  }

input {
    margin-bottom: 5vh;
    border-right: none;
    border-top: none;
    border-left: none;
    background-color:transparent;
    outline: none;
    color: white;
    caret-color: white;
}

input[type=submit] {
    background: rgba(255,255,255,.2);
    width: 100%;
    padding: 2vh;
    border: none;
    cursor: pointer;
    border-radius: 4px 4px;
}

ul {
    color: white;
    list-style: none;
}

strong {
    color: white;
}

li {
    margin-left: -30px;
}

text {
    color: white;
}

